import { memo } from 'react';
import RightsSection from './RightsSection';
import ContactSection from './ContactSection';
import regione1 from '@assets/images/home/regione1.png';
import regione2 from '@assets/images/home/regione2.png';
// import Actions from './Actions';

import styles from './style.module.scss';

const MarketFooter = () => (
  <footer className={styles.root}>
    <div className={styles.root__wrapper}>
      <RightsSection />
      <ContactSection />
    </div>
    <div className={styles.container}>
      <div>
        <img src={regione1} alt='' className={styles.img1} />
      </div>
      <div className={styles.text}>
        Progetto sviluppato con il contributo del PNRR, M1C3 - INVESTIMENTO 3.3- Supporto ai settori culturali e creativi per l’innovazione
        e la transizione digitaleTOCC0002831 - COR 15911011 - CUP C77J23000840008
      </div>
      <div>
        <img src={regione2} alt='' className={styles.img2} />
      </div>
    </div>
    {/* <Actions /> */}
  </footer>
);

export default memo(MarketFooter);
